<template>
  <div class="dashboard">
    <TheHeader></TheHeader>
    <SiteNav></SiteNav>
    <div class="px-3 py-3  bg-lines bg-cover bg-fixed bg-center">
      <div class="md:container md:mx-auto">
      <!-- <h1 class="dashboard-greeting">Guten Tag, {{ user.first_name }}!</h1> -->
      <Greeting :user="user" class="my-6 text-shadow"></Greeting>

      <div class="alertbox border-color-brand bg-card-content">
        <div class="alertbox-icon bg-brand">
          <font-awesome-icon icon="tools" class="icon text-4xl"/>
        </div>
        <div class="alertbox-content">
          <p class="alertbox-heading text-blue-500 text-xl">
            <b>👋 Willkommen zur Beta</b>
          </p>
          <p class="alertbox-maincontent">
            Vielen Dank, für Deine Teilnahme am StadtbahnfahrerClub. Wir
                  freuen uns sehr, dass Du hier bist!<br />
                  Nur gemeinsam können wir die
                  Plattform der Zukunft auch in Deinen Verkehrsbetrieb
                  vollständig integrieren.<br />
                  Beachte bitte, dass sich Funktionen ändern und Probleme auftreten
                  können.<br />
                  Sende Dein Feedback gerne per E-Mail an <i><a class="link" href="mailto:hallo@stadtbahnfahrer.club">hallo@stadtbahnfahrer.club</a></i> oder nutze das <a class="link" href="https://docs.google.com/forms/d/e/1FAIpQLSca0IyZZxAfmkb9v3urR6dxp1VvpSK6a7-sVfxHVPJq8COplA/viewform?usp=pp_url" target="_newTab">Feedback-Formular</a>. <br />
                  Vielen Dank für Deine Unterstützung!
          </p>
          <p class="alertbox-hint">
            Dein StadtbahnfahrerClub<br>
            Marcel & Tobi
          </p>
        </div>
      </div>

      <!-- <div class="alert alert-default my-3 p-3 rounded-lg text-black bg-gray-300">
        <div class="flex gap-6 flex-col sm:flex-row">
          <div class="self-center pl-3">
            <font-awesome-icon icon="tools" class="icon text-4xl text-blue-900" />
          </div>
          <div class="">
            <div class="divide-y-2 divide-blue-900">
              <div
                class="
                  leading-6
                  space-y-4
                  sm:leading-7
                  pb-1
                "
              >
                <p class="alert-heading text-bold">
                  <b>Du nutzt eine Pre-Alpha-Version</b>
                </p>
              </div>
              <div
                class="
                pt-1
                  leading-6
                  space-y-4
                  sm:leading-7
                "
              >
                <p>
                  Vielen Dank, für Deine Teilnahme am StadtbahnfahrerClub. Wir
                  freuen uns sehr, dass Du hier bist!<br />
                  Wenn Dir der StadtbahnfahrerClub gefällt, teile ihn bitte mit
                  Deinen Kolleginnen und Kollegen.<br />Nur gemeinsam können wir die
                  Plattform der Zukunft auch in Deine Verkehrsgesellschaft
                  vollständig integrieren.<br />
                  Beachte bitte, dass sich Funktionen ändern und Probleme auftreten
                  können.<br />
                  Sende Dein Feedback gerne an: feedback@stadtbahnfahrer.club<br />
                  Vielen Dank für Deine Unterstützung!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <!-- <PremiumTable></PremiumTable> -->

      <!-- <div
        class="
          grid grid-cols-1
          sm:grid-cols-2
          xl:grid-cols-2
          gap-3
          text-center
        "
      > -->
      <div class="notification flex gap-6 p-3 my-3 text-black bg-gray-200 rounded-lg" v-if="!user.confirmed">
        <div class="self-center pl-3">
          <font-awesome-icon icon="envelope" class="icon text-4xl text-blue-900" />
        </div>
        <div class="">
          <p class="headline text-lg leading-6 font-medium">E-Mail Adresse bestätigen</p>
          <p class="text">
            Bestätige bitte Deine E-Mail Adresse.
          </p>
          <button type="button" class="button-filled text-white bg-blue-900 hover:bg-blue-800 rounded-lg p-3 mt-3" @click="requestEmailConfirmationen" v-if="requestEmailConfirmationenShowButton">
            E-Mail erneut zusenden?
          </button>
          <span class="text" v-else>
            <p class="text text-green-600"  v-if="requestEmailConfirmationenSuccess">
              <font-awesome-icon icon="check" class="color-success" /> Wir haben Dir eine E-Mail an <i>{{user.email}}</i> gesendet. Prüfe bitte Dein E-Mail Postfach.
            </p>
            <p class="text text-gray-600"  v-else>
              Einen Moment bitte...
            </p>
          </span>
        </div>
      </div>
      <div class="notification flex gap-6 p-3 my-3 text-black bg-gray-200 rounded-lg" v-if="!user.verified">
        <div class="self-center pl-3">
          <font-awesome-icon icon="id-card" class="icon text-4xl text-blue-900" />
        </div>
        <div class="">
          <p class="headline text-lg leading-6 font-medium">Konto verifizieren</p>
          <!-- user.verification[0].file_servicecard_front == null && user.verification[0].file_servicecard_back == null && !user.verification[0].verified_status -->
          <p v-show="!userIsVerificated" class="text">
            Verifiziere bitte Dein Konto, um alle Funktionen nutzen zu können.
          </p>
          <button v-show="!userIsVerificated" type="button" class="button-filled text-white bg-blue-900 hover:bg-blue-800 rounded-lg p-3 mt-3" @click="showModal">
            Verifizierung abschließen
          </button>
          <!-- <p v-show="user.verification[0].file_servicecard_front != null && user.verification[0].file_servicecard_back != null && !user.verification[0].verified_status" class="text text-blue-900">
            <font-awesome-icon icon="hourglass-start" class="color-brand " />
            Verifizierung wird geprüft. Bitte habe etwas Geduld!
          </p> -->
          <!-- user.verification[0].file_servicecard_front != null && user.verification[0].file_servicecard_back != null && user.verification[0].verified_status -->
          <p v-show="userIsVerificated" class="text text-green-600">
            <font-awesome-icon icon="check" class="color-success " /> <b>Vielen Dank für Deine Verifizierung!</b><br>Wir werden Deine Informationen prüfen und uns bei Dir melden.
          </p>
        </div>
      </div>
      <Modal v-show="isModalVisible" @close="closeModal" />


      <div class="cards-grid grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 my-5 gap-4 mt-6">
        <div class="card rounded-lg bg-blue-900 flex flex-col justify-between">
          <strong class="card-title text-center p-3 text-lg font-bold">Dienstplan</strong>
          <hr class="card-line" />
          <div class="card-content flex-grow flex items-center justify-center">
            <div class="card-box space-y-4" v-if="shifts.length > 0 && nextShift()">
              <p class="shift-date text-center font-bold">
                {{ getWeekdayForDate(nextShift().header_date, true) }}, den
                {{ getFormatedDate(nextShift().header_date) }}
              </p>
              <div class="shift flex flex-row items-center justify-center text-center space-x-8">
                <div class="shift-detail">
                  <p class="heading"><b>Beginn</b></p>
                  <p class="details">
                    {{ nextShift().shift[0].start_location }}<br />{{
                      nextShift().shift[0].start_time
                    }}
                    Uhr
                  </p>
                </div>
                <div class="shift-arrow">&rarr;</div>
                <div class="shift-detail">
                  <p class="heading"><b>Ende</b></p>
                  <p class="details">
                    {{
                      nextShift().shift[nextShift().shift.length - 1]
                        .end_location
                    }}<br />{{
                      nextShift().shift[nextShift().shift.length - 1].end_time
                    }}
                    Uhr
                  </p>
                </div>
              </div>
            </div>

            <div class="card-box" v-else>
              <p class="text-center">
                Es liegen uns keine Informationen zu Deinem nächsten Dienst vor. <router-link to="../syncmanager" class="link">Synchronisiere</router-link> regelmäßig Deine Dienste mit uns, damit wir Dir Deinen Dienstplan zu senden und Statistiken für Dich erstellen können.
              </p>
            </div>
          </div>
          <router-link to="/dienstplan" class="button-in-card"
            ><span class="font-semibold">Dienstplan ansehen</span></router-link
          >
        </div>

        <div class="card rounded-lg bg-blue-900 flex flex-col justify-between">
          <strong class="card-title text-center p-3 text-lg font-bold">Tauschbörse</strong>
          <hr class="card-line w-10/12 mx-auto" />
          <div class="card-content">
            <div class="card-box">
              <div class="exchange-details">
                <div class="title my-auto">Offene Anfragen</div>
                <div class="badge badge-white my-auto">
                  {{exchangeRequests}}
                </div>
                <!-- <div class="badge badge-white my-auto">
                  ??
                </div> -->
              </div>
              <!-- <hr /> -->
              <div class="exchange-details">
                <div class="title my-auto">Offene Angebote</div>
                <div class="badge badge-white my-auto">
                  {{exchangeOffers}}
                </div>
                <!-- <div class="badge badge-white my-auto">
                  ??
                </div> -->
              </div>
            </div>
          </div>
          <router-link to="/tauschboerse" class="button-in-card"
            ><span  class="font-semibold">Tauschbörse öffnen</span></router-link
          >
        </div>

        <div class="card rounded-lg bg-blue-900 flex flex-col justify-between">
          <strong class="card-title text-center p-3 text-lg font-bold">Board</strong>
          <hr class="card-line w-10/12 mx-auto" />
          <div class="card-content">
            <div class="card-box">
              <div class="exchange-details">
                <div class="title my-auto">Langsamfahrstellen</div>
                <div class="badge badge-white my-auto" v-if="speedlimits">
                  {{ speedlimits.meta.filter_count }}
                </div>
              </div>
              <!-- <hr /> -->
              <div class="exchange-details">
                <div class="title my-auto">Baustellen</div>
                <div class="badge badge-white my-auto" v-if="constructionsites">
                  {{ constructionsites.meta.filter_count }}
                </div>
              </div>
            </div>
          </div>
          <router-link to="/board" class="button-in-card"
            ><span  class="font-semibold">Board öffnen</span></router-link
          >
        </div>

        <!--
        <div class="card rounded-lg bg-blue-900 flex flex-col justify-between">
          <strong class="card-title text-center p-3 text-lg font-bold">Mitfahrzentrale</strong>
          <hr class="card-line w-10/12 mx-auto" />-->
          <!-- <div class="card-content ">
            <div class="card-box">
              <div class="exchange-details ">
                <div class="title my-auto">Angebotende Fahrten</div>
                <div class="badge badge-white my-auto">??</div>
              </div>
              <div class="exchange-details">
                <div class="title my-auto">Mitfahrten</div>
                <div class="badge badge-white my-auto">??</div>
              </div>
            </div>
          </div> 
          <router-link to="/mitfahrzentrale/anbieten" class="button-in-card"
            ><span>Fahrt anbieten</span></router-link
          >
          -->
          <!--
          <div class="card-content text-center flex-grow flex flex-col items-center justify-center gap-3">
            <font-awesome-icon icon="tools" class="icon text-4xl text-white" />
            <p>
              Noch nicht verfügbar.
            </p>
          </div>
          <router-link to="/mitfahrzentrale" class="button-in-card"
            ><span>Mehr erfahren</span></router-link
          >
        </div>
        -->
        <div class="card rounded-lg bg-blue-900 flex flex-col justify-between">
          <strong class="card-title text-center p-3 text-lg font-bold">Hilfe-Center</strong>
          <hr class="card-line w-10/12 mx-auto" />
          <div class="card-content text-center flex-grow flex flex-col items-center justify-center gap-3">
            <!-- <font-awesome-icon icon="tools" class="icon text-4xl text-white" />-->
            <p>
              Du hast Fragen?<br>Im Hilfe-Center findest Du viele Antworten.
            </p> 
          </div>
          <a href="https://hilfe.stadtbahnfahrer.club" target="_newTab" class="button-in-card"
            ><span class="font-semibold">Hilfe-Center öffnen</span></a
          >
        </div>

        <div class="card rounded-lg bg-blue-900 flex flex-col justify-between">
          <strong class="card-title text-center p-3 text-lg font-bold">Telegram</strong>
          <hr class="card-line w-10/12 mx-auto" />
          <div class="card-content text-center">
            <p>
              Erhalte über den Instant-Messenger Telegram Benachrichtungen zu
              Deinem Konto, wie z.B. Deinen nächsten Dienst und wichtige
              Statusänderungen.
            </p>
          </div>
          <router-link to="/telegram" class="button-in-card"
            ><span class="font-semibold">Telegram einrichten</span></router-link
          >
        </div>

        <div class="card rounded-lg bg-blue-900 flex flex-col justify-between">
          <strong class="card-title text-center p-3 text-lg font-bold">App <i>SyncManager</i></strong>
          <hr class="card-line w-10/12 mx-auto" />
          <div class="card-content text-center">
            <p>
              Mit unserer App SyncManager für Dein Android & iOS Smartphone
              kannst Du bequem Deinen vollständigen Dienstplan mit dem
              StadtbahnfahrerClub synchronisieren.
            </p>
          </div>
          <router-link to="/syncmanager" class="button-in-card"
            ><span class="font-semibold">App installieren</span></router-link
          >
        </div>

        <!-- <div class="card">
          <strong class="card-title">Browsererweiterung</strong>
          <hr class="card-line" />
          <div class="card-content">
            <p>
              Mit der Erweiterung für Deinen Browser kannst Du bequem Deinen
              vollständigen Dienstplan mit dem StadtbahnfahrerClub
              synchronisieren.
            </p>
          </div>
          <router-link to="/extension" class="button-in-card"
            ><span>Erweiterung installieren</span></router-link
          >
        </div> -->
      </div>

      <!-- <div class="cards-grid">
        <div class="card">
          <strong class="card-title">Telegram</strong>
          <hr class="card-line" />
          <div class="card-content">
            <p>
              Erhalten über den Instant-Messagener Telegram Benachrichtungen zu
              Deinem Konto, wie z.B. Deinen nächsten Dienst und wichtige
              Statusänderungen.
            </p>
          </div>
          <a class="button-in-card"><span>Telegram einrichten</span></a>
        </div>
      </div> -->
    </div>
  </div>
  </div>
</template>


<script>
import SiteNav from "@/components/TheNavigation";
import TheHeader from "@/components/TheHeader";
import Modal from "@/components/Modal";
import Greeting from "@/components/Greeting.vue";
import PremiumTable from "@/components/PremiumTable.vue";

import { mapGetters } from "vuex";

export default {
  name: "Dashboard",
  components: {
    SiteNav,
    TheHeader,
    Modal,
    Greeting,
    PremiumTable
  },
  data() {
    return {
      isModalVisible: false,
      speedlimits: false,
      exchangeOffers: 0,
      exchangeRequests: 0,
      constructionsites: false,
      boardInformations: false,
      userIsVerificated: false,
      requestEmailConfirmationenShowButton: true,
      requestEmailConfirmationenSuccess: false,
    };
  },
  methods: {
    showModal() {
      this.isModalVisible = true;
      document.body.classList.add("modal-open");
    },
    closeModal() {
      this.isModalVisible = false;
      document.body.classList.remove("modal-open");
    },
    requestEmailConfirmationen() {
      this.requestEmailConfirmationenShowButton = false;

      this.$store.dispatch("requestEmailConfirmationen", true).then((a) => {
      if (a.status) {
        this.requestEmailConfirmationenSuccess = true;
      } else {
        this.requestEmailConfirmationenSuccess = false;
      }
    });
    }
  },
  mounted() {
    // fetchUserProfile already called in store/index.js
    this.$store.dispatch("fetchShifts",true);

    // this.$store.dispatch("fetchSpeedlimits", true).then((a) => {
    //   console.log(a);

    //   if (a.status == true) {
    //     this.exchangeOffers = a.result;
    //   } else {
    //     // this.showAlertWrongData = true
    //   }

    //   // this.isLoading = false;
    // });

    this.$store.dispatch("fetchSpeedlimits", true).then((a) => {
      if (a.status == true) {
        this.speedlimits = a.result;
      } else {
        // this.showAlertWrongData = true
      }

      // this.isLoading = false;
    });

    this.$store.dispatch("fetchBoardConstructionsites", true).then((a) => {
      if (a.status == true) {
        this.constructionsites = a.result;
      } else {
        // this.showAlertWrongData = true
      }

      // this.isLoading = false;
    });

    this.$store.dispatch("fetchExchangeCounter", true).then((a) => {
      console.log(a);

      if (a.status == true) {
        this.exchangeOffers = a.result.response.offers;
        this.exchangeRequests = a.result.response.requests;
      } else {
        // this.showAlertWrongData = true
      }

      // this.isLoading = false;
    });

    if(this.$route.query.verify) {
      this.showModal();
    }
  },
  watch: {
    user: function (val) {
      // console.log("WATCH user")
      // console.log(val)

      if(val.verification) {
        this.userIsVerificated = true
      } else {
        this.userIsVerificated = false
      }
    },
    "$route.query.verify": {
      handler: function(value) {
        // console.log("route.query.verify",value);
        if(this.$route.query.verify) {
          this.showModal();
          this.$route.query.verify = 0;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      shifts: "getShiftsForCurrentMonth",
      getWeekdayForDate: "getWeekdayForDate",
      getFormatedDate: "getFormatedDate",
      nextShift: "getNextShifts",
    }),
  },
};
</script>

<style scoped>
  .button-in-card {
    @apply bg-white text-black text-center w-full rounded-b-lg p-3 hover:bg-gray-300;
  }
  .card-line {
    @apply w-11/12 mx-auto;
  }
  .card-content {
    @apply my-4 mx-4 sm:mx-6 h-full;
  }

  .exchange-details {
    @apply flex items-stretch justify-between mx-1 my-3;
  }

  .exchange-details .badge {
    @apply bg-white text-black rounded-full px-3 text-center;
    min-width: 3rem;
  }

  /* .card-box hr {
    @apply  mx-auto border-black;
  } */

  
  
</style>