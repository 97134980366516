<template>
  <div id="myModal" class="modal fixed z-10 inset-0 overflow-y-auto">
    <div class="modal-background"></div>
    <!-- Modal content -->
    <div class="modal-dialog">
      <!-- <span class="inline-block align-middle h-screen" aria-hidden="true">&#8203;</span> -->
      <div class="modal-content">
        <div class="modal-header">
          <h2>Verifizierung abschließen</h2>
          <span class="close" @click="close">&times;</span>
        </div>
        <div class="modal-body">
            <p class="text-center">
            <font-awesome-icon icon="id-card" class="modal-icon text-6xl text-blue-500 mb-3" />
            </p>

            <div v-if="user.verified">
              <p class="text-center mb-1"><b>Vielen Dank für Deine Verifizierung!</b><br>Die Verifizieung ist abgeschlossen.</p>
            </div>
            <div v-else-if="isUploadComplete || user.verification">
              <p class="text-center mb-1"><b>Vielen Dank für Deine Verifizierung!</b><br>Wir werden Deine Informationen prüfen und uns bei Dir melden.</p>
            </div>

            <div v-else-if="!isUplading">
            
              <p class="text-center mb-1">Der StadtbahnfahrerClub ist ausschließlich für Personal im Fahrbetrieb. Damit wir die Verifizierung Deines Kontos abschließen können, benötigen wir noch Informationen. Der Funktionsumfang ist bis zur vollständigen Verifizierung eingeschränkt.</p>
              

              <div class="alertbox border-color-brand bg-card-content">
                <div class="alertbox-icon bg-brand">
                  <font-awesome-icon icon="mobile" class="icon text-4xl"/>
                </div>
                <div class="alertbox-content">
                  <p class="alertbox-heading text-blue-500 text-xl">
                    <b>📲 Verifizierung per App abschließen</b>
                  </p>
                  <div class="alertbox-maincontent">
                    <div class="flex flex-col sm:flex-row">
                      <div>
                        <p>Mit unserer kostenfrei <b>StadtbahnfahrerClub Sync-App</b> für iOS & Android kannst Du Deine Verifizierung schnell und einfach auch direkt per Smartphone-Kamera abschließen.</p>
                        <p><a href="/syncmanager" target="_newTab" class="link">Mehr Infos zur App <font-awesome-icon icon="arrow-alt-circle-right" class="icon"/></a></p>
                      </div>
                      <img src="@/assets/qrcode_app_syncmanager.png" class="h-24 mx-auto hidden sm:block"/>
                    </div>
                  </div>
                </div>
              </div>
              
              
              <p class="font-bold mt-8">Persönliche Informationen</p>
              <p class="text-sm mb-3">Prüfe die persönlichen Informationen auf Korrektheit, die Du während der Registrierung eingegeben hast. <a href="/hilfe" class="link" target="_newTab">Kontaktiere uns</a> bitte bei fehlerhafte Daten.</p>
              <div class="grid grid-cols-6 gap-6">
                  <div class="col-span-6 sm:col-span-3">
                    <label for="first-name" class="block text-sm font-medium ">Vorname</label>
                    <input :value="user.first_name" disabled type="text" name="first-name" id="first-name" autocomplete="given-name" class="mt-1 p-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md text-black" />
                  </div>

                  <div class="col-span-6 sm:col-span-3">
                    <label for="last-name" class="block text-sm font-medium ">Nachname</label>
                    <input :value="user.last_name" disabled type="text" name="last-name" id="last-name" autocomplete="family-name" class="mt-1 p-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md text-black" />
                  </div>

                  <div class="col-span-6">
                    <label for="last-name" class="block text-sm font-medium ">Personalnummer</label>
                    <input :value="user.servicecard_id" disabled type="text" name="workid" id="workid"  class="mt-1 p-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md text-black" />
                  </div>
              </div>

              <p class="font-bold mt-8">Dienstausweis</p>
              <p class="text-sm mb-3">Die Informationen auf Deinem Ausweis müssen klar erkennbar sein. Lade jeweils ein Foto bzw. einen Scan von Vorder- sowie Rückseite hoch - auch bei unbeschrifteter Rückseite.</p>
              <div class="grid grid-cols-6 gap-6">
                  <div class="col-span-6 lg:col-span-3">
                    <label for="first-name" class="block text-sm font-medium ">Vorderseite</label>
                    <input @change="setFile_verificationCardFront" type="file" class="text-center mt-1" value="" required />
                  </div>
                  <div class="col-span-6 lg:col-span-3">
                    <label for="last-name" class="block text-sm font-medium ">Rückseite</label>
                    <input @change="setFile_verificationCardBack" type="file" class="text-center mt-1" value="" required />
                  </div>
              </div>
              <p class="input-helper-text text-gray-400 text-sm mb-3">Dienstausweis-Dokumente werden nach der Verifizierung <b>gelöscht</b>.</p>

              <button :disabled="submitButtonDisabled" @click="submitData()" class="button-filled text-center btn-fullsize w-full p-3 my-6">Zur Überprüfung einreichen</button>
              <p class="text-center input-helper-text text-gray-400 text-sm">Die Überprüfung kann einige Tage dauern. Die Daten für die Verifizierung müssen innerhalb von 30 Tagen ab Registrierung eingereicht werden, ansonsten wird Dein Konto automatisch gelöscht. Wenn Du Schwierigkeiten hast Deine Verifizierung abzuschließen, kannst Du uns auch gerne Deinen Ausweis per E-Mail (<i><a href="mailto:hallo@stadtbahnfahrer.club" class="link">hallo@stadtbahnfahrer.club</a></i>) zusenden.</p>
            </div>

            <div v-else>
              <div v-if="isUploadComplete">
                <p class="text-center mb-1"><b>Vielen Dank für Deine Verifizierung!</b><br>Wir werden Deine Informationen prüfen und uns bei Dir melden.</p>
              </div>
              <div v-else>
                <p class="text-center mb-1">Informationen werden gespeichert...</p>
              </div>
            </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Modal",
  data() {
    return {
      show: this.showModal,
      verificationCardFront: null,
      verificationCardBack: null,
      isUplading: false,
      isUploadComplete: false,
      submitButtonDisabled: true
    };
  },
  props: {
    image: String,
    showModal: false,
  },
  computed: {
    ...mapGetters({
      user: "getUser",
    }),
  },
  methods: {
    close() {
      this.$emit("close");
    },
    setFile_verificationCardFront(event) {
      this.verificationCardFront = event.target.files[0];
      this.enableSubmitButton();
    },
    setFile_verificationCardBack(event) {
      this.verificationCardBack = event.target.files[0];
      this.enableSubmitButton();
    },
    enableSubmitButton() {
      if(this.verificationCardFront != null || this.verificationCardBack != null) {
        this.submitButtonDisabled = false;
      }
    },
    submitData(){
      if(this.verificationCardFront == null || this.verificationCardBack == null) {
        alert('Wähle Deinen Dienstausweis aus.');
        return
      }

      this.isUplading = true

      this.$store.dispatch("uploadVerification",{"front": this.verificationCardFront, "back": this.verificationCardBack}).then((a) => {
        if(a.response.status == 200) {
          this.isUploadComplete = true
        } else {
          if(a.error == 802) {
            alert('Die Informationen konnten nicht gespeichert werden. Du hast ggf. bereits die Verifizierung gestartet. Kontaktiere uns gerne per E-Mail (hallo@stadtbahnfahrer.club), falls Du Hilfe brauchst.');
            return
          }
          this.isUplading = false
          this.isUploadComplete = false
        }
      });
    }
  },
};
</script>




