<template>
  <div class="content-card w-full ">
    <div class="flex text-center items-center">

      <div class="w-1/4 h-full shadow-lg rounded-md bg-gray-700 p-2">
        <p class="text-center text-xl">Bronze</p>
        <button class="button-filled w-full">Gönn' Dir</button>
      </div>
      <div class="w-2/4 shadow-lg rounded-md bg-gray-700 p-2">
        <p class="text-center text-xl">Silber</p>
        <div class="flex flex-col items-center py-3">
          <p><font-awesome-icon icon="tasks" class="icon"/> Monatlicher Dienstplan</p>
          <p><font-awesome-icon icon="tasks" class="icon"/> Monatlicher Dienstplan</p>
        </div>
        <p class="text-lg font-bold  py-3">3,99 EUR</p>
        <button class="button-filled w-full">Gönn' Dir</button>
      </div>
      <div class="w-1/4 h-full shadow-lg rounded-md bg-gray-700 p-2">
        <p class="text-center text-xl">Gold</p>
        <button class="button-filled w-full">Gönn' Dir</button>
      </div>

    </div>
    
  </div>
</template>

<script>
import { mapGetters } from "vuex";


export default {
  name: "PremiumTable",
  components: {
    
  },
  data() {
    return {
      
    };
  },
  props: {
    
  },
  methods: {
    
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters([
    ]),
  },
};
</script>