<template>
    <div>
        <h1 class="dashboard-greeting text-3xl sm:text-left text-center">{{ g }},<br class="block sm:hidden"> {{ user.first_name }} <font-awesome-icon icon="smile-wink" class="icon text-shadow"/></h1>
    </div>
</template>

<script>
    export default {
        name: 'Greeting',
        props: ["user"],
        data() {
            return {
                g: "Hallo"
            }
        },
        methods: {
            greeting() {
                var i = 0;
                var hour = new Date().getHours();
                var dayParts = [6,12,18,24];//Keep in this order	
                var greetings = [
                    'Guten Morgen', //this too
                    'Guten Morgen',
                    'Guten Tag',
                    'Guten Abend'
                ];

                var greet = function() {
                    if( hour < dayParts[i] ) {
                        return greetings[i];
                    }
                    else{	
                        i++;
                        return greet();
                    }
                };
                return greet();
            }
        },
        mounted() {
            this.g = this.greeting()
        }
    }

</script>
